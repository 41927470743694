#new_stream__blur {
  bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

#new_stream__blur.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

#new_stream,
#new_stream__blur {
  display: flex;
  color: #000;
  box-sizing: border-box;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: bottom !important;
  transition: 0.8s;
  -webkit-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1.000);
  /* easeInOutCubic */
  max-width: 100vw;
}

.new_stream__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 100vw;
  width: 100vw;
}

.new_stream__container__header {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
}

.new_stream__container__header .logo {
  margin-top: 1rem;
  width: 55%;
}

.new_stream__container_submit .btn_primary {
  pointer-events: all;
}


.new_stream__container_submit .btn_disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.new_stream__container__header .spacer {
  display: flex;
  width: 35%;
}

.new_stream__content_main {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  width: 60%;
}

.new_stream__content_main .login_modal {
  background: rgba(255, 255, 255, 0.85);
  border: 2px solid #000;
  border-radius: 2rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.new_stream__content_main .login_modal .star {
  position: absolute;
  top: -1rem;
  width: 3rem;
}

.new_stream__content_main .login_modal .star.left {
  left: -1rem;
}

.new_stream__content_main .login_modal .star.right {
  right: -1rem;
}

.new_stream__input {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.new_stream__input label,
.stream__settings_block_title {
  display: inline-block;
  margin-bottom: 0.8rem;
  color: #000;
  text-align: center;
  width: 100%;
}

.new_stream__input input {
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  text-align: center;
  outline: none;
  width: 80%;
}

.new_stream__container_submit .btn {
  background: black;
  border-radius: 1rem;
  color: whitesmoke;
  display: inline-block;
  padding: 0.9rem 2.5rem;
  font-size: 1.3rem;
  box-shadow: 0 17px 11px rgb(106 87 215 / 20%);
  margin: 0.6rem auto 0 auto;
}

.new_stream__slider_block {
  align-items: center;
  display: flex;
  justify-content: center;
}

.new_stream__slider_block .avatar_slider__btn.left {
  margin-right: 1rem;
}

.new_stream__slider_block .avatar_slider__btn.right {
  margin-left: 1rem;
}

.new_stream__container .footer {
  margin: 1rem auto 1rem auto;
}

.new_stream__container .footer .title {
  color: #3e3e3e;
  font-size: 0.8rem;
}

.new_stream__container .footer .spacer {
  align-self: center;
  justify-content: flex-end;
  display: flex;
  position: relative;
}

.new_stream__container .footer .footer__social_container {
  margin: 0;
}

#new_stream .chapis {
  position: absolute;
  left: 5%;
  bottom: 0;
  width: 9rem;
  z-index: 10;
}

@media (max-width: 480px) {
  #new_stream {
    padding: 1rem;
  }

  .new_stream__container__header {
    flex-direction: column;
    padding: 1rem 5%;
  }

  .new_stream__container__header .spacer {
    width: 100%;
  }

  .new_stream__container__header .logo {
    width: 50%;
  }

  .new_stream__container__header .title {
    font-size: 1.8rem;
    margin-top: 0.5rem;
    text-align: center;
    width: 100%;
  }

  .new_stream__container {
    width: 100%;
  }

  .new_stream__content_main {
    width: 90%;
  }

  #new_stream .chapis {
    display: none;
  }
}