@font-face {
  font-family: InterBold;
  src: url("./assets/fonts/Inter-Bold.otf") format("opentype");
}

@font-face {
  font-family: InterExtraBold;
  src: url("./assets/fonts/Inter-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: InterExtraLight;
  src: url("./assets/fonts/Inter-ExtraLight.otf") format("opentype");
}

@font-face {
  font-family: InterLight;
  src: url("./assets/fonts/Inter-Light.otf") format("opentype");
}

@font-face {
  font-family: InterSemiBold;
  src: url("./assets/fonts/Inter-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: InterThin;
  src: url("./assets/fonts/Inter-Thin.otf") format("opentype");
}

@font-face {
  font-family: AvenirBold;
  src: url("./assets/fonts/AvenirNext-Bold.woff2") format("woff2"),
    url("./assets/fonts/AvenirNext-Bold.woff") format("woff");
}

@font-face {
  font-family: AvenirMedium;
  src: url("./assets/fonts/AvenirNext-Medium.woff2") format("woff2"),
    url("./assets/fonts/AvenirNext-Medium.woff") format("woff");
}

@font-face {
  font-family: AvenirItalic;
  src: url("./assets/fonts/AvenirNext-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/AvenirNext-MediumItalic.woff") format("woff");
}

html,
body,
#root,
.main {
  font-family: AvenirMedium;
  color: #5e5e5e;
  height: 100%;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: AvenirBold;
}

.text_center {
  text-align: center;
}

.font_kansas {
  font-family: kansasnew, serif;
}

.font_azo {
  font-family: azo-sans-uber, sans-serif;
  font-weight: 100;
}

.kansas_semibold {
  font-family: kansasnew, serif;
  font-weight: 600;
}

.font_azo {
  font-family: "azo-sans-web";
  font-weight: 400
}

.pointer {
  cursor: pointer;
}

.font_bold {
  font-family: AvenirBold;
}

.font_italic {
  font-family: AvenirItalic;
}

.uppercase {
  text-transform: uppercase;
}

.d-none {
  display: none;
}

* {
  font-size: 1.05rem;
  font-family: kansasnew, serif;
}

.main_bg {
  background: #E6DFD7;
}

.page {
  background: #E6DFD7;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eae9f1', endColorstr='#b999e2', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.btn {
  cursor: pointer;
}

.btn_black {
  background: #000;
  color: #fff;
}

.red_color {
  color: #F2504F;
}

.black_shadow {
  box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.5),
    1px 4px 21px -1px rgba(0, 0, 0, 0);
}

.purple_font {
  color: #6a57d7;
}

.purple_btn {
  background-color: #6a57d7;
}

.pink_btn {
  background-color: #eb4692;
  color: whitesmoke;
}

.grey_btn {
  background-color: #e9e9e9;
  color: #5e5e5e;
}

.green_color {
  background-color: #77c8b9;
}

.powered_by_hoppia {
  color: #5e5e5e;
}

.main_section {
  margin: 0 auto;
  max-width: 1500px;
  width: 82%;
}

/* Login*/
.diagonal_gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eae9f1+0,b999e2+100 */
  background: rgb(234, 233, 241);
  /* Old browsers */
  background: -moz-linear-gradient(-45deg,
      rgba(234, 233, 241, 1) 0%,
      rgba(185, 153, 226, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,
      rgba(234, 233, 241, 1) 0%,
      rgba(185, 153, 226, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,
      rgba(234, 233, 241, 1) 0%,
      rgba(185, 153, 226, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eae9f1', endColorstr='#b999e2', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.login_modal {
  background-color: #ebebeb;
  border: solid 3px #000;
  color: #757575;
}

/* Fondos */
.radial_gradient_bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#967edc+0,350678+100 */
  background: rgb(150, 126, 220);
  /* Old browsers */
  background: -moz-radial-gradient(center,
      ellipse cover,
      rgba(150, 126, 220, 1) 0%,
      rgba(53, 6, 120, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center,
      ellipse cover,
      rgba(150, 126, 220, 1) 0%,
      rgba(53, 6, 120, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center,
      rgba(150, 126, 220, 1) 0%,
      rgba(53, 6, 120, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#967edc', endColorstr='#350678', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.pink_bg {
  background-color: #eb4692;
}

.green_bg {
  background-color: #00b140;
}

.page.loader {
  align-items: center;
  display: flex;
  justify-content: center;
}

.cartoober__header {
  align-items: center;
  border-radius: 1.8rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.8rem;
  padding: 1rem 1.8rem;
}

.cartoober__header .logo {
  width: 80%;
}

.cartoober__header .burguer_menu {
  display: inline-block;
  font-size: 2rem;
}

.header_menu {
  display: none;
  position: absolute;
  flex-direction: row;
  height: 4rem;
  align-items: center;
  right: 3rem;
  gap: 2rem;
}

.header_menu div {
  font-size: 0.9rem;
  cursor: pointer;
}

.header_menu div:hover {
  color: #000;
}

.header_menu :nth-child(3) {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Mobile devices */
@media (max-width: 480px) {
  .cartoober__header {
    box-sizing: border-box;
    margin: 1rem auto 0 auto;
    width: 90%;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .cartoober__header {
    border-radius: 3rem;
    margin-bottom: 3rem;
    padding: 1rem 2rem;
    width: 90%;
  }

  .cartoober__header .logo {
    width: 100%;
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {}

/* Desktops, large screens  */
@media (min-width: 1025px) and (max-width: 1920px) {}

/* Desktops, large screens  */
@media (min-width: 1921px) {
  * {
    font-size: 1.4rem;
  }
}


@media (min-width: 769px) {
  .cartoober__header {
    border-radius: 1.8rem;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    width: 30%;
    border: none;
    background-color: unset;
  }

  .header_menu {
    display: flex;
  }
}