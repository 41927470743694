body {
  overscroll-behavior: contain;
}

#stream,
.stream__live,
.stream__live_container {
  height: 100%;
  overflow: hidden;
}

#stream .icon.active {
  color: crimson;
}

#video_loader {
  background: rgba(0, 0, 0, 0.5);
  color: #ebebeb;
  align-items: center;
  justify-content: center;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}

#video_loader.mobile {
  position: fixed;
}

#video_loader.active {
  display: flex;
}

.stream_cavas {
  position: relative;
}

#stream .cartoober__header {
  top: 5%;
  position: absolute;
  z-index: 10;
}

#stream .bottom_stream_menu {
  bottom: 0;
  height: 5rem;
  left: 0;
  width: calc(75% - 4.5rem);
  display: none;
  font-size: 0.3rem;
  align-items: center;
  justify-content: center;
  gap: 1.7rem;
  width: 100%;
}

#stream .bottom_stream_menu>.btn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
  color: #000;
}

#stream .bottom_stream_menu>.btn ul {
  bottom: 100%;
  background: #EBEBEB;
  font-family: "azo-sans-web";
  padding: 1rem;
  border-radius: 1rem;
  width: max-content;
  border: solid #000 3px;
  position: absolute;
}

#stream .bottom_stream_menu>.btn li {
  font-family: "azo-sans-web";
  list-style-type: none;
  padding-top: 0.5rem;
}

#stream .bottom_stream_menu * {
  font-size: 0.7rem;
}

#stream .stream__menu_container_buttons>.btn ul {
  left: -80vw;
  bottom: -80vw;
  background: #EBEBEB;
  font-family: "azo-sans-web";
  padding: 1rem;
  border-radius: 1rem;
  width: max-content;
  border: solid #000 3px;
  position: absolute;
  color: #000;
}

#stream .stream__menu_container_buttons>.btn li {
  font-family: "azo-sans-web";
  list-style-type: none;
  padding-top: 0.5rem;
}


.video_item_link {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#stream .stream__settings_videoList .title {
  margin: 00.7rem;
}

#stream .stream__settings_videoList ul {
  padding: 0;
  margin: 0;
}

#stream .stream__settings_videoList li {
  align-items: center;
  list-style: none;
  justify-content: space-between;
  display: flex;
  border-radius: 0.5rem;
  cursor: pointer;
}

#stream .stream__settings_videoList li:hover {
  background: #e0e0e0;
}

#stream .stream__settings_videoList li a {
  padding: 0.5rem 0.7rem;
}

#stream .stream__settings_videoList li span {
  cursor: pointer;
  color: rgb(134, 134, 134);
  display: flex;
  opacity: 0;
}

#stream .stream__settings_videoList li:hover span {
  opacity: 1;
}

#stream .stream__settings_videoList li span:hover {
  color: crimson;
}

#stream .stream__settings_videoList a {
  color: #000;
  text-decoration: none;
  padding: 0
}

.video_list.center {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

#stream .bottom_stream_menu .icon {
  font-size: 1.3rem;
}

.record {
  position: relative;
}

.record.mobile:not(.recording) {
  filter: invert();
}

.record .recording img {
  animation-name: recording;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes recording {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.stream__live_container {
  background: green;
}

.stream__settings {}

.stream_cavas__content video {
  display: none;
}

.stream_cavas__loading {
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  display: flex;
  color: whitesmoke;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
}

.stream__live_container {
  background: black;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.stream_cavas__content {
  transform: scaleX(-1);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  border-radius: 8px;
  background: #222;
  width: 15rem;
}

.stream__settings {
  position: absolute;
  padding: 0.54rem;
  z-index: 100;
}

.stream__settings_options {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.stream__settings_option {
  display: inline-block;
  cursor: pointer;
  margin: 0 0.5rem;
  padding-bottom: 5px;
  font-weight: 100;
  color: #000;
}

.stream__settings_option.active {
  border-bottom: 2px solid #000;
}

.stream__settings_title {
  align-items: center;
  display: flex;
  justify-content: center;
}

.stream__settings_block {
  margin: 0 auto;
  width: 60%;
}

.stream__settings_title input {
  border: none;
  border-radius: 10px;
  display: flex;
  padding: 1rem 0.5rem;
  outline: none;
  width: 80%;
  height: 1em;
}

.show_title_btn {
  font-size: 2.5rem;
  display: inline-block;
  margin-left: 1rem;
  margin-bottom: -0.4rem;
}

.show_title_btn.active {
  color: #000;
}

.stream__menu {
  align-items: center;
  color: whitesmoke;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 3%;
  top: 20%;
  z-index: 2;
}

.stream__menu_container {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.stream__menu_container .btn {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.stream__menu_container .btn:hover,
.stream__settings_close .btn:hover {
  color: #6a57d7;
}

.stream__settings_close {
  display: flex;
  justify-content: flex-end;
}

.stream__settings_close .btn {
  display: inline-block;
  padding: 0.5rem;
  pointer-events: all;
}

.stream__settings_content {
  max-width: 1000px;
  margin: 0 auto;
  background: #ebebeb;
}

.fullscreen .stream__settings,
.fullscreen .stream_cavas__content,
.fullscreen .hide_fs,
/*.fullscreen .cartoober__header,*/
.fullscreen .stream__feedback,
.bottom_stream_menu {
  display: none;
}

.fullscreen .icon-fullscreen {
  opacity: 0.5;
}

.streaming__btn {
  background: rebeccapurple;
  padding: 1rem;
  border-radius: 1rem;
  color: whitesmoke;
  position: absolute;
  left: 5%;
  bottom: 5%;
}

.streaming__btn.active {
  background: crimson;
}

#video {
  background: #000;
  bottom: 5%;
  right: 3%;
  position: absolute;
  width: 25vmin;
  z-index: 1000;
}

#ul {
  z-index: 10000;
}


/* Mobile devices */
@media (max-width: 480px) {
  #new_stream {
    padding-top: 1.5rem;
  }

  .stream_cavas__content {
    height: 100px;
    right: 3%;
    top: 60%;
    width: 100px;
  }

  .stream__settings_block {
    width: 80%;
  }

  .stream__settings_title {
    margin-bottom: 2rem;
  }

  .stream__settings {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: unset !important;
  }

  #stream .cartoober__header {
    left: 0;
    right: 0;
  }

  .stream__settings_options .icon-fullscreen {
    display: none;
  }

  .stream_cavas__content video {
    display: block;
    width: 100%;
    height: 100%;
  }

  .stream_cavas__content .guides {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .stream__feedback {
    display: none;
  }

  #stream .stream__settings {
    overflow: hidden;
  }

  #stream .stream__settings {
    height: 10.5rem;
    transition: 0.25s;
  }

  #stream .stream__settings.dragging {
    transition: none;
  }

  #stream .stream__settings.expanded {
    height: 60vh;
  }

  #stream .stream__settings_content {
    height: calc(10.5rem - 4rem);
    height: 6.5rem;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.25s;
  }

  #stream .stream__settings.dragging .stream__settings_content {
    transition: none;
  }

  #stream .stream__settings.expanded .stream__settings_content {
    height: calc(60vh - 4rem);
    overflow-y: scroll;
  }

  #stream .stream__settings_videoList ul {
    padding-bottom: 2rem;
  }

  #stream .stream__settings_videoList li a {
    padding: 0.8rem 0.7rem;
  }

  #stream .stream__settings_videoList li:hover {
    background: unset;
  }

  #stream .stream__settings_videoList li span {
    opacity: 1;
  }
}

@media (min-width: 481px) {}

@media (min-width: 769px) {
  .stream__feedback {
    align-items: center;
    display: flex;
    color: whitesmoke;
    position: absolute;
    bottom: 3%;
    right: 3%;
  }

  .stream__feedback_text {
    margin-right: 1rem;
    text-align: right;
    text-shadow: 0 0 10px rgb(0 0 0 / 50%);
  }

  .stream__feedback_text h2,
  .stream__feedback_text p {
    margin: 0;
  }

  .stream__feedback_logo {
    background: white;
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 0.5rem;
    height: 10vmin;
    width: 10vmin;
  }

  .stream__feedback_logo img {
    width: 100%;
    height: 100%;
  }

  #stream .cartoober__header {
    left: 3%;
  }

  .stream__settings_options {
    flex-direction: row-reverse;
  }

  .stream__menu_container {
    background: transparent;
  }

  .stream__menu_container .btn {
    margin-bottom: 1.5rem;
  }

  .stream__menu_container .icon-sign-out {
    margin-bottom: 0;
  }

  .stream__settings_option {
    margin: 0 1rem 0 0.5rem;
    font-size: 1.4rem;
  }

  .stream_cavas__content {
    height: 10vw;
    width: 15vw;
    right: 8%;
    top: 5%;
  }

  .stream__menu_active .stream_cavas__content {
    right: 28%;
  }

  .stream_cavas__content {
    right: 2.5% !important;
    top: 4%;
  }

  .stream__menu {
    top: 5%;
  }

  .stream_cavas__content video {
    display: block;
    width: 100%;
    height: 100%;
  }

  .stream_cavas__content .guides {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .stream__settings .icon-close {
    font-size: 1rem;
    display: none;
  }

  .stream__settings .icon-close.video_close {
    font-size: 1rem;
    display: inline-block;
  }

  .stream__settings_close {
    position: absolute;
    right: 0.5rem;
  }

  .stream__settings {
    background: white !important;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    right: 2%;
    top: 2%;
    bottom: 20%;
    width: 25%;
    padding: 0;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }

  .stream__settings_content {
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
  }

  .stream__settings_options {
    margin: 10px 0 0 0;
  }

  .stream__settings_option {
    padding-bottom: 1rem;
  }

  .stream__settings_buttons {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  .stream__settings_content {
    padding: 2rem 0.5rem 0.5rem;
  }

  .stream__settings_block {
    width: 80%;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {}

/* Desktops, large screens  */
@media (min-width: 1025px) and (max-width: 1920px) {}


/* Desktops, large screens  */
@media (min-width: 1921px) {}

@media (min-width: 769px) {

  .stream__live_container {
    height: 1080px;
    width: 1920px;
    transform-origin: left top;
    border-radius: 2rem;
    position: absolute;
  }

  .fullscreen .stream__live_container {
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    transform: scale(1) !important;
  }

  #stream .cartoober__header {
    top: 0;
    left: 2rem;
    width: 13rem;
  }

  .stream__settings_title {
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    gap: 1rem;
  }

  .show_title_btn {
    margin: 0 7%;
  }

  .stream__settings_title input {
    margin: 0 7%;
  }

  .stream__feedback {
    display: none;
  }

  .stream__menu {
    display: none;
  }

  #stream .bottom_stream_menu {
    display: flex;
  }

  .fullscreen .bottom_stream_menu>div:not(.fullscreen-btn) {
    display: none !important;
  }

  #stream .stream_player {
    margin: 4rem 1.5rem 0 1.5rem;
  }

  .stream__live {
    height: calc(100% - 5.5rem);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr max(min(20rem, 30%), 15rem);
    gap: 1rem;
    max-height: calc(100vh - 4rem);
    justify-content: center;
  }

  .fullscreen .stream__live {
    width: 100vw;
    height: 100vh !important;
    position: absolute;
    left: 0px;
    top: 0px;
    display: block;
    max-height: 100vh;
  }

  .fullscreen .fullscreen-btn {
    right: 2rem;
    top: 2rem;
    position: absolute;
  }

  .stream__live .stream_container {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 1fr 5rem;
  }

  .stream__settings {
    position: initial;
    width: auto;
    height: auto;
  }
}