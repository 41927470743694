.avatar_slider__container {
  margin: 0 5%;
}

.avatar_slider .slick-prev:before,
.avatar_slider .slick-next:before {
  color: #6A57D7;
}

.avatar_slider__item {
  background: #cac0b6;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  border-radius: 50%;
  border: 3px solid #ebebeb;
  box-shadow: 0 0 0 4px transparent;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0.4rem;
  max-height: 4rem;
  min-height: 4rem;
  max-width: 4rem;
  min-width: 4rem;
  position: relative;
  /*box-shadow: 0 17px 11px rgb(106 87 215 / 20%);*/
  overflow: hidden;
}

.avatar_slider__item.selected {
  border: 3px solid whitesmoke;
  box-shadow: 0 0 0 4px #cac0b6;
}

.avatar_slider__item:hover {
  opacity: 0.8;
}

.avatar_slider__item img {
  width: 100%;
}

.avatar_slider__item.square {
  border-radius: 0.5rem;
}

/* Mobile devices */
@media (max-width: 480px) {
  .avatar_slider__container {
    margin: 0;
  }

  .avatar_slider__grid {
    display: none;
  }
}


/* Desktops, large screens  */
@media (min-width: 769px) {
  .avatar_slider.grid .avatar_slider__container {
    display: none;
  }

  .avatar_slider.slider .avatar_slider__grid {
    display: none;
  }

  .avatar_slider.grid .avatar_slider__grid {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {}

/* Desktops, large screens  */
@media (min-width: 1025px) and (max-width: 1920px) {}

/* Desktops, large screens  */
@media (min-width: 1921px) {}