.login_form {
  border: solid 2px #000;
  border-radius: 5rem;
  padding: 5rem 3rem;
  max-width: 500px;
  background-color: #f1ebe6;
}

.login_form .auth__title {
  text-align: center;
  margin-top: 0;
}

.login_form__divider {
  align-items: center;
  display: flex;
  margin-bottom: 40px;
}

.login_form__divider .line {
  border-bottom: solid 1px #000;
  height: 0;
  width: 100%;
}

.login_form__divider p {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0 1.5rem;
}

.login_form__social button {
  position: relative;
}

.login_form__social .spacer {
  margin-right: 10px;
}

.login_form__field {
  border: solid 2px #000;
  border-radius: 1rem;
  box-sizing: border-box;
  margin: 15px 0 30px;
  position: relative;
}

.login_form__field label {
  font-size: 0.7rem;
  left: 10%;
  padding: 0 10px;
  position: absolute;
  top: -0.5rem;
  background-color: #f1ebe6;
}

.login_form__field input,
.login_form__field textarea {
  box-sizing: border-box;
  background: transparent;
  border: none;
  font-size: 1rem;
  outline: none;
  padding: 1.2rem 0.9rem;
  overflow: hidden;
  width: 100%;
  border-radius: 1rem;
}

.login_form__options {
  display: flex;
  justify-content: center;
}

.login_form__options .divider {
  padding: 0 10px;
}

.login_form__options * {
  color: #000;
  font-size: 0.7rem;
}

.login_form__options b {
  color: #000;
}

.login_form__social {
  margin-bottom: 30px;
}

.login_form__social button {
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  margin-bottom: 0.8rem;
  padding: 0.7rem 0.2rem;
  overflow: hidden;
  width: 100%;
  background-color: #fff;
}

.login_form__social button:nth-child(2) {
  background-color: #007eff;
  color: #fff;
}

.login_form__social button * {
  font-size: 1rem;
}

.login_form__buttons {
  display: flex;
  justify-content: center;
}

.login_form__btn {
  background: #000;
  border: none;
  border-radius: 1rem;
  color: #CCC;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 1rem;
  margin: 0 auto;
  width: 80%;
}

.login_form .error {
  color: crimson;
  font-size: 0.8rem;
  text-align: center;
}