#auth {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#auth .privacy {
  font-size: 0.8rem;
  text-align: center;
}

.auth__container {
  color: #000;
  flex-grow: 1;
}

.auth__form label {
  background: #E6DFD7;
}


.auth__form .home__btn:hover {
  color: #E8C15A;
}

.auth__container .login_form {
  background: transparent;
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .auth__collage {
    display: none;
  }

  .auth__form .home__btn {
    font-size: 2rem;
    display: inline-block;
    margin: 5%;
  }

  .auth__form {
    margin: 0 auto;
    width: 90%;
  }

  .auth__form h1 {
    text-align: center;
  }
}

@media screen and (min-width: 481px) {
  .auth__container {
    display: flex;
  }

  .auth__form .home__btn {
    font-size: 2rem;
    left: 5%;
    position: absolute;
    top: 5%;
  }

  .auth__form {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 50%;
  }

  .auth__collage {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0.7rem;
    grid-row-gap: 0.7rem;
    padding: 2% 3%;
    width: 50%;
  }

  .auth__collage .item {
    border: 2px solid #000;
    border-radius: 2rem;
    box-sizing: border-box;
    padding: 15%;
  }

  .auth_collage_item_1 {
    background: #E8C15A;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  .auth_collage_item_2 {
    background: #F2504E;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .auth_collage_item_3 {
    background: #F2504E;
    grid-column: 3/4;
    grid-row: 1 / 2;
  }

  .auth_collage_item_4 {
    background: #819B5C;
    grid-column: 3/4;
    grid-row: 2 / 3;
  }

  .auth_collage_item_5 {
    background: #038BD3;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }

  .auth_collage_image {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    box-sizing: border-box;
    height: 100%;
    max-width: 100%;
  }
}

@media screen and (min-height: 1000px) {
  .auth__form h1 {
    margin-bottom: 5rem;
  }
}